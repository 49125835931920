<template>
  <v-dialog v-model="dialog" max-width="600px" @click:outside="close">
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="isNewUser = true" depressed color="info" v-bind="attrs" v-on="on" :disabled="!currentTenant || [...moduleRoles].length === 0">
        add new user
      </v-btn>
    </template>
    <v-card>
      <v-card-title> {{ formTitle }} </v-card-title>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-card-text>
          <v-container>
            <form id="tenant_user_form">
              <v-container>
                <v-row>
                  <v-col>
                    <!-- Name -->
                    <validation-provider
                      v-slot="{ errors }"
                      name="Name"
                      rules="required:true|between-length:2,100"
                    >
                      <v-text-field
                        dense
                        outlined
                        v-model="formData.first_name"
                        :error-messages="errors"
                        label="Name"
                        required
                      ></v-text-field>
                    </validation-provider>
                    <!-- Last Name -->
                    <validation-provider
                      v-slot="{ errors }"
                      name="Last Name"
                      rules="required:true|between-length:2,100"
                    >
                      <v-text-field
                        dense
                        outlined
                        v-model="formData.last_name"
                        :error-messages="errors"
                        label="Last Name"
                        required
                      ></v-text-field>
                    </validation-provider>
                    <!-- Second Last Name -->
                    <validation-provider
                      v-slot="{ errors }"
                      name="Second Last Name"
                    >
                      <v-text-field
                        dense
                        outlined
                        :disabled="loadings.second_last_name"
                        v-model="formData.second_last_name"
                        :error-messages="errors"
                        label="Second Last Name"
                        required
                      ></v-text-field>
                    </validation-provider>
                    <!-- EMAIL -->
                    <validation-provider
                        ref="emailValidator"
                      v-slot="{ errors }"
                      name="Email"
                      rules="required:true|email:true|between-length:8,150"
                    >
                      <v-text-field
                          id="email"
                        dense
                        outlined
                        v-model="formData.email"
                        :error-messages="errors"
                        label="Email"
                        required
                        :readonly="!isNewUser"
                        @blur="isEmailValid()"
                      ></v-text-field>
                    </validation-provider>
                    <!-- PHONE -->
                    <validation-provider
                      v-slot="{ errors }"
                      name="Phone Number"
                      :rules="{
                        required: true,
                      }"
                    >
                      <v-text-field
                        dense
                        outlined
                        :disabled="loadings.formBtn"
                        v-model="formData.phone_number"
                        :error-messages="errors"
                        label="Phone Number"
                        required
                        v-mask="'###-###-####'"
                      ></v-text-field>
                    </validation-provider>
                    <!-- POSITION -->
                    <validation-provider
                      v-slot="{ errors }"
                      name="Position"
                      :rules="{
                        required: true,
                      }"
                    >
                      <v-text-field
                        dense
                        outlined
                        :disabled="loadings.formBtn"
                        v-model="formData.position"
                        :error-messages="errors"
                        label="Position"
                        required
                      ></v-text-field>
                    </validation-provider>
                    <!-- Password -->
                    <validation-provider
                    v-slot="{ errors }"
                    name="Password"
                    :rules="{
                        required: isNewUser ? true : false,
                        password: true
                    }"
                    >
                    <v-text-field
                        dense
                        outlined
                        :disabled="loadings.formBtn"
                        v-model="formData.password"
                        :error-messages="errors"
                        label="Password"
                        :required="isNewUser ? true : false"
                        :type="passFlag ? 'text' : 'password'"
                        @click:append="passFlag = !passFlag"
                        :append-icon="passFlag ? 'mdi-eye' : 'mdi-eye-off'"
                    ></v-text-field>
                    </validation-provider>

                    <v-row>
                      <v-col>
                        <!-- inverted true/false values because the variable is named 'active' -->
                        <v-checkbox
                            :false-value="1"
                            :true-value="0"
                            label="Disabled"
                            color="secondary"
                            v-model="formData.active"
                            >
                        </v-checkbox>
                      </v-col>

                      <v-col>
                        <v-checkbox
                            :false-value="0"
                            :true-value="1"
                            label="Receive Email Notifications"
                            color="secondary"
                            v-model="formData.can_receive_email"
                        >
                        </v-checkbox>
                      </v-col>
                    </v-row>

                    <!-- Role -->
                    <validation-provider
                      v-slot="{errors}"
                      name="Allec Role"
                      :rules="{
                        required: true
                      }"
                    >
                      <v-select
                          dense
                          outlined
                          :disabled="loadings.formBtn"
                          v-model="role"
                          :error-messages="errors"
                          label="Allec Role"
                          required
                          item-text="name"
                          item-value="id"
                          :items="[...moduleRoles.find(mod => mod.id == 0).roles]"
                      >

                      </v-select>
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      v-if="type != 'supplier' && tenantFormData.im_data.isEnabled"
                      name="Inv Role"
                      :rules="{
                        required: true,
                      }"
                    >
                      <v-select
                          dense
                          outlined
                          :disabled="loadings.formBtn"
                          v-model="inventoryRole"
                          :error-messages="errors"
                          label="Inv Role"
                          required
                          item-text="name"
                          item-value="id"
                          :items="[...moduleRoles.find(mod => mod.id == vue_app_inv_id).roles]"
                      >

                      </v-select>
                    </validation-provider>
                    <validation-provider
                        v-if="tenantFormData.wms_data.isEnabled"
                        v-slot="{ errors }"
                        name="WMS Role"
                        :rules="{
                        required: true,
                      }"
                    >
                      <v-select
                          dense
                          outlined
                          :disabled="loadings.formBtn"
                          v-model="wmsRole"
                          :error-messages="errors"
                          :label="'WMS Role'"
                          required
                          item-text="name"
                          item-value="id"
                          :items="[...moduleRoles.find(mod => mod.id == vue_app_wms_id).roles]"
                      >
                      </v-select>
                    </validation-provider>
                    <validation-provider
                        v-slot="{ errors }"
                        v-if="tenantFormData.mp_data.isEnabled"
                        name="Marketplace Role"
                        :rules="{
                        required: true,
                      }"
                    >
                      <v-select
                          dense
                          outlined
                          :disabled="loadings.formBtn"
                          v-model="mktRole"
                          :error-messages="errors"
                          label="Mkt Role"
                          required
                          :items="mktRoleOptions"
                      >
                      </v-select>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="clear" text> reset </v-btn>
          <v-btn text @click="close"> Close </v-btn>
          <v-btn
            @click="submit"
            color="success"
            text
            type="submit"
            :disabled="invalid"
            :loading.sync="loadings.formBtn"
          >
            {{confirmBtnText}}
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, digits, max, regex, email } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import tenantApi from "@/api/tenant";

setInteractionMode("eager");

extend("email", {
  ...email,
  message: "Email must be valid",
});

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} not is valid",
});

extend('password', {
  validate(value) {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#_=+])[A-Za-z\d@$!%*?&^#_=+]{8,}$/;
    return passwordRegex.test(value);
  },
  message: 'Password must have at least 8 characters, with at least 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character',
});

extend('between-length', {
  validate(value, {min, max}) {
    return value.length >= min && value.length <= max;
  },
  params: ['min', 'max'],
  message: 'The {_field_} field must have at least {min} characters and {max} characters at most'
});

export default {
  /************************************************************************************
   *                                    COMPONENTS
   * **********************************************************************************/
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  /************************************************************************************
   *                                    PROPS
   * **********************************************************************************/
  props: ['type'],

  /************************************************************************************
   *                                    DATA
   * **********************************************************************************/
  data() {
    return {
      passFlag: false,
      dialog: false,
      uploadDialog: true,
      loadings: {
        formBtn: false,
      },
      tenantUserData: {},
      wmsRole: "",
      wmsRoleOptions: [],
      inventoryRole: "",
      inventoryRoleOptions: [],
      mktRole: "",
      mktRoleOptions: [],
      role: "",
      roleOptions: [],
      formData: {
        first_name: "",
        last_name: "",
        second_last_name: "",
        email: "",
        phone_number: "",
        position: "",
        password: "",
        roles: [],
        active: true,
        can_receive_email: false,
      },
      isNewUser: true,
      vue_app_wms_id: process.env.VUE_APP_WMS_ID,
      vue_app_inv_id: process.env.VUE_APP_INVENTORY_ID
    };
  },


  /************************************************************************************
   *                                    COMPUTED
   * **********************************************************************************/
  computed: {
    ...mapGetters(["currentTenant", "moduleRoles", "tenantFormData"]),

    formTitle() {
      const self = this;
      let title = "";

      if (self.tenantUserData.id) {
        title = `Editing: ${self.tenantUserData.first_name}`;
      } else {
        title = `New User`;
      }

      return title;
    },

    confirmBtnText() {
      const self = this;
      let title = "";

      if (self.tenantUserData.id) {
        title = `Update`;
      } else {
        title = `Create`;
      }

      return title;
    },
  },

  /************************************************************************************
   *                                    ACTIONS
   * **********************************************************************************/
  actions: {

  },

  /************************************************************************************
   *                                    METHODS
   * **********************************************************************************/
  methods: {
    ...mapActions(["createTenantsUser", 'updateTenantsUser']),

    close() {
      const self = this;
      self.dialog = false;
      self.$nextTick(() => {
        self.clear();
      });
    },

    open(data = null) {
      if (!data.id) return;

      // const self = this;

      // console.log("DATA");
      // console.log(data);
      this.tenantUserData = data;
      this.formData = {
        first_name: data.first_name,
        last_name: data.last_name,
        second_last_name: data.second_last_name,
        email: data.email,
        phone_number: data.phone_number,
        position: data.position,
        id: data.id,
        active: data.active,
        can_receive_email: data.can_receive_email
      };

      this.isNewUser = false;
      this.dialog = true;
    },

    /* CREATE USER */
    async submit() {
      // const self = this;
      const valid = await this.$refs.observer.validate();

      if (!valid) return;

      let module_roles = [
        {
          id: this.vue_app_inv_id,
          roles: [this.inventoryRole]
        },
        {
          id: this.vue_app_wms_id,
          roles: [this.wmsRole]
        },
        {
          id: process.env.VUE_APP_MARKETPLACE_ID,
          roles: [this.mktRole]
        }
      ]

      this.formData.roles = [this.roles]

      let account_type = ''

      if (this.type == 'supplier') {
        account_type = 'supplier'
      } else {
        account_type = this.mktRole
      }

      const userData = {
        ...this.formData,
        username: this.formData.email,
        module_roles: module_roles,
        tenant_id: this.currentTenant.id,
        account_type: account_type,
        roles: [this.role]
      }

      if (!this.tenantUserData.id) {
        this.formBtn = true;

        this.createTenantsUser(userData)
            .then((suss) => {
              this.showNotification({
                type: 1,
                text: suss.message,
                lngKey: false,
              });

              this.$nextTick(() => {
                this.clear();
                this.dialog = false;
              });
            }).catch((err) => {
              this.$swal.fire({
                timer: 4000,
                icon: "error",
                title: err.statusText,
                text: err.data.message || "Error trying to store Tenant user",
              });
            }).finally(() => {
              this.formBtn = false;
            });
      } else {
        this.updateTenantsUser(userData)
            .then((suss) => {
              this.showNotification({
                type: 1,
                text: suss.message,
                lngKey: false,
              });

              this.$nextTick(() => {
                this.clear();
                this.dialog = false;
              });
            }).catch((err) => {
              this.$swal.fire({
                timer: 4000,
                icon: "error",
                title: err.statusText,
                text: err.data.message || "Error trying to store Tenant user",
              });
            }).finally(() => {
              this.formBtn = false;
            });
      }
    },

    clear() {
      const self = this;
      self.$refs.observer.reset();
      self.tenantUserData = {};
      self.formData = {
        first_name: "",
        last_name: "",
        second_last_name: "",
        email: "",
        phone_number: "",
        position: "",
        password: "",
        active: true,
        can_receive_email: false,
      };
    },

    async checkEmailExist(email) {
      const { data } = await tenantApi.checkEmailExist(email);

      return data;
    },

    isEmailValid() {
      if (!this.isNewUser) {
        //field is disabled
        return;
      }

      if (this.formData.email === undefined || this.formData.email == "") {
        return; //dont call api
      }

      this.checkEmailExist(this.formData.email).then((result) => {
        if (result.emailExist === true) {
          this.$swal.fire({
            timer: 4000,
            icon: "warning",
            title: "Sorry",
            text: "The entered email is already in use",
          });

          this.$refs.emailValidator.setErrors(["Email already in use"]);
        }
      }).catch(() => {
        this.$swal.fire({
          timer: 4000,
          icon: "error",
          title: "Sorry",
          text: "An error occurred during the email validation",
        });
      });
    }
  },

  /************************************************************************************
   *                                    WATCH
   * **********************************************************************************/
  watch: {

  },

  /************************************************************************************
   *                                    CREATED
   * **********************************************************************************/
  created() {

  },

  /************************************************************************************
   *                                    MOUNTED
   * **********************************************************************************/
  mounted() {
      // this.wmsRoleOptions =
      console.log("This is the code", this.vue_app_wms_id);
      if(this.type == 'supplier'){
          this.mktRoleOptions = ['supplier']
      } else{
          this.mktRoleOptions = ['buyer', 'buyer admin']
      }
  },
};
</script>
