<template>
  <div>
    <v-list>
      <!-- 1 -->
      <v-list-item v-if="type == 'buyer'">
        <v-list-item-icon>
          <v-img
            class="mx-auto"
            width="200"
            src="@/assets/Allec-New-Logo-K.png"
            contain
          ></v-img>
        </v-list-item-icon>

        <v-list-item-title class="text-h6 text-uppercase">
          know your customer
        </v-list-item-title>

        <v-list-item-action>
          <v-switch
            color="teal darken-2"
            inset
            v-model="kyc_data"
          ></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <!-- 2 -->
      <v-list-item>
        <v-list-item-icon>
          <v-img
            class="mx-auto"
            src="@/assets/Allec-New-Logo-M.png"
            width="200"
            contain
          ></v-img>
        </v-list-item-icon>

        <v-list-item-title class="text-h6 text-uppercase"
          >Marketplace</v-list-item-title
        >
        <v-list-item-action>
            <!--              :disabled="!marketplaceSubscriptionIsActive"-->
            <v-switch
            color="teal darken-2"
            inset
            v-model="mp_data"
          ></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <!-- 3 -->
      <v-list-group v-if="type == 'buyer'" append-icon="">
        <template v-slot:activator>
          <v-list-item-icon>
            <v-img
              class="mx-auto"
              src="@/assets/Allec-New-Logo-I.png"
              width="140"
              contain
            ></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-h6 text-uppercase">
              inventory Management
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="mr-n3">
<!--            <v-btn icon @click="inventoryShowMore = !inventoryShowMore">-->
<!--              <v-icon>{{ inventoryShowMore ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>-->
<!--            </v-btn>-->
            <v-switch
                :disabled="!inventorySubscriptionIsActive"
              color="teal darken-2"
              inset
              v-model="im_data"
            ></v-switch>
          </v-list-item-action>
        </template>

<!--        <span v-for="([title, icon], i) in cruds" :key="i">-->
<!--          <v-list-item>-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title-->
<!--                v-text="title"-->
<!--                class="font-weight-bold"-->
<!--              ></v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--            <v-list-item-action :key="icon">-->
<!--              <v-switch :disabled="!inventorySubscriptionIsActive" color="teal darken-2" inset></v-switch>-->
<!--            </v-list-item-action>-->
<!--          </v-list-item>-->
<!--          <v-divider v-if="i < cruds.length - 1"></v-divider>-->
<!--        </span>-->
      </v-list-group>
      <v-divider></v-divider>

      <!-- 4 -->
      <v-list-group append-icon="">
        <template v-slot:activator>
<!--        <template v-slot:activator>-->
          <v-list-item-icon>
            <v-img
                class="mx-auto"
                src="@/assets/Allec-New-Logo-W.png"
                width="140"
                contain
            ></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-h6 text-uppercase">
              Warehouse Management
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="mr-n3">
<!--            <v-btn icon @click="wmsShowMore = !wmsShowMore">-->
<!--              <v-icon>{{ wmsShowMore ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>-->
<!--            </v-btn>-->

<!--              :disabled="!warehouseSubscriptionIsActive"-->
              <v-switch
                color="teal darken-2"
                inset
                :disabled="mp_data && type == 'buyer'"
                v-model="wms_data"
            ></v-switch>
          </v-list-item-action>
        </template>

<!--        <span v-for="([title, icon], i) in cruds" :key="i">-->
<!--          <v-list-item>-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title-->
<!--                  v-text="title"-->
<!--                  class="font-weight-bold"-->
<!--              ></v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--            <v-list-item-action :key="icon">-->
<!--              <v-switch :disabled="!warehouseSubscriptionIsActive" color="teal darken-2" inset></v-switch>-->
<!--            </v-list-item-action>-->
<!--          </v-list-item>-->
<!--          <v-divider v-if="i < cruds.length - 1"></v-divider>-->
<!--        </span>-->
      </v-list-group>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-action>
          <v-btn
            class="mr-4"
            type="button"
            v-on:click="submit"
          >
            Submit
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-dialog
        v-model="dialog"
        hide-overlay
        persistent
        width="300"
    >
      <v-card
          color="secondary"
          dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ['type'],
  created(){

  },

  data: () => ({
    dialog: false,
    wmsShowMore: false,
    inventoryShowMore: false,
    cruds: [
      ["Inventory Levels", "mdi-plus-outline"],
      ["Cycle Count Creation", "mdi-file-outline"],
      ["Cycle Count", "mdi-update"],
      ["Reconciliation", "mdi-delete"],
    ],
  }),

  computed: {
    ...mapGetters(["currentTenant"]),
    mp_data: {
      get() {
        return this.$store.getters["modulesMpData"].isEnabled;
      },
      set(value) {
        let data = {
          key: "mp_data",
          data: { isEnabled: value },
        };
        this.$store.commit("setTenantsFrmData", data);
      },
    },
    im_data: {
      get() {
        return this.$store.getters["modulesImData"].isEnabled;
      },
      set(value) {
        let data = {
          key: "im_data",
          data: { isEnabled: value },
        };
        this.$store.commit("setTenantsFrmData", data);
      },
    },
    kyc_data: {
      get() {
        return this.$store.getters["modulesKycData"].isEnabled;
      },
      set(value) {
        let data = {
          key: "kyc_data",
          data: { isEnabled: value },
        };
        this.$store.commit("setTenantsFrmData", data);
      },
    },
    wms_data: {
      get() {
        return this.$store.getters["modulesWmsData"].isEnabled;
      },

      set(value) {
        let data = {
          key: "wms_data",
          data: { isEnabled: value },
        };
        this.$store.commit("setTenantsFrmData", data);
      },
    },

    marketplaceSubscriptionIsActive() {
      return this.$store.getters.isMarketPlaceSubscriptionActive;
    },

    inventorySubscriptionIsActive() {
      return this.$store.getters.isInventorySubscriptionActive;
    },

    warehouseSubscriptionIsActive() {
      return this.$store.getters.isWarehouseSubscriptionActive;
    }
  },

  methods: {
    ...mapActions([
      "createTenantModule",
    ]),

    submit() {
      let params = this.$route.params;

      if (!params.tenant_id) {
        this.$swal.fire({
          timer: 4000,
          icon: "warning",
          title: "Ooops",
          text: "Please select a tenant",
        });

        return;
      }


      let tenantFrmData = this.$store.state.tenant.tenantFormData

      let modules = [];

      // Set Inventory
      if(tenantFrmData.im_data.isEnabled){
        modules.push(process.env.VUE_APP_INVENTORY_ID)
      }
      // Set WMS
      if(tenantFrmData.wms_data.isEnabled){
        modules.push(process.env.VUE_APP_WMS_ID)
      }
      //Set Marketplace
      if(tenantFrmData.mp_data.isEnabled){
        modules.push(process.env.VUE_APP_MARKETPLACE_ID)
      }



      let data = {
        tenant_id: this.$store.state.tenant.tenantCurrent.id,
        modules: modules,
        domain: this.$store.state.tenant.tenantCurrent.domain
      }

      const currentTenant = this.currentTenant

      if (currentTenant.is_supplier) {
        if (currentTenant.supplier) {
          this.dialog = true;

          this.createTenantModule(data)
              .then((suss) => {
                this.dialog = false;

                this.$store.dispatch("showNotification", {
                  type: 1,
                  text: suss.message,
                  lngKey: false,
                });
              })
              .catch((err) => {
                  this.dialog = false;

                  this.$swal.fire({
                      timer: 4000,
                      icon: "error",
                      title: "Module Creation Failed",
                      text: err,
                      toast: true,
                      position: "bottom-end",
                      timerProgressBar: true,
                      showConfirmButton: false,
                      showCloseButton: true,
                  });
              })
        } else {
            this.$swal.fire({
                timer: 4000,
                icon: "error",
                title: "Invalid Supplier",
                text: "To submit marketplace module supplier form must be filled",
            }); 
        }
      } else {
        this.dialog = true;

        this.createTenantModule(data)
          .then((suss) => {
            this.dialog = false;

            this.showNotification({
              type: 1,
              text: suss.message,
              lngKey: false,
            });
          })
          .catch((err) => {
              this.dialog = false;

              this.$swal.fire({
                  timer: 4000,
                  icon: "error",
                  title: "Module Creation Failed",
                  text: err,
                  toast: true,
                  position: "bottom-end",
                  timerProgressBar: true,
                  showConfirmButton: false,
                  showCloseButton: true,
              });
          })
      }
    }
  }
};
</script>

<style>
</style>
