<template>
    <v-container>
        <v-file-input placeholder="Upload your documents" label="File input" :multiple="allowMultipleFiles"
            :accept="acceptedFileTypes" chips :prepend-icon="icon" @change="onFileChange" @click:clear="onClear">
        </v-file-input>
        <v-container class="bg-surface-variant">
            <v-row no-gutters>
                <v-col v-for="(preview, index) in previews" :key="index" cols="12" sm="4">
                    <v-sheet class="ma-2 pa-2">
                        <img :src="preview" class="preview-image" v-if="preview" />
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>
  
<script>

export default {
    data: () => ({
        files: [],
        previews: [],
    }),
    props: {
        allowMultipleFiles: {
            type: Boolean,
            default: false
        },
        acceptedFileTypes: {
            type: String,
            default: "*/*"
        },
        icon: {
            type: String,
            default: "mdi-paperclip"
        },
        previewUrls: {
            type: [],
            default: []
        }
    },
    watch: {
        previewUrls(newValue) {
            if (Array.isArray(newValue)) {
                this.previews = newValue
            } else {
                this.previews = [newValue]
            }
        }
    },
    methods: {
        onClear: function () {
            this.files = []
            this.previews = []
        },
        onFileChange(files) {
            if (files) {
                if (this.allowMultipleFiles) {
                    this.previews = this.files.map(element => {
                        if (element instanceof File) {
                            return URL.createObjectURL(element)
                        }
                        return element
                    });
                } else {
                    this.files = files;
                    this.previews = [files instanceof File ? URL.createObjectURL(files) : files]
                }
                this.$emit("input", this.files);
            }
        }
    }
}
</script>

<style>
.preview-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
</style>